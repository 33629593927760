<template>
  <div class="page-documentation">
    <b-container fluid>
      <b-row>
        <b-col class="text-center">
          <iframe src="https://docs.google.com/document/d/e/2PACX-1vQWvc-22fQzWZcyItObw9DmHYmXbqmpYEoyeG1GIpDBZ3AWEEpkL2P3f1pXoayf1DTFoZ1l3oXnUKGe/pub?embedded=true" style="width:1000px;height:10000px;margin:0 auto;border:none"></iframe>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'DocumentationView',
}
</script>